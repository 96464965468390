<template>

  <div class="container">

    <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
      <Preloader/>
    </div>

    <div class="my-4" v-else>
    <!--
    <div class="vacancies-form mt-4" v-else>
      <div class="col-md-3" v-if="getUserRole == 'HRHead' || getUserRole == 'admin'">
        <label for="filterFilterDepartments">Фильтрация по кафедрам</label>
        <select class="form-control form-select" id="filterFilterDepartments"
                @change="changeFilterDepartments">
          <option v-for="(item, index) in [{id: 0, name_ru: 'Выберите'}, {id: 0, name_ru: 'Все'}, ...departments]"
                  :value="item.id"
                  :key="index"
                  :selected="item.id==departmentID">{{ item.name_ru }}
          </option>
        </select>
      </div>
      <div class="col-md-3" v-if="getUserRole == 'HRHead' || getUserRole == 'admin'">
        <label >Не подтвержденные : </label>
        <input type="checkbox" name="sort_by_confirm" true-value="1"  false-value="0" v-model="sort_by_confirm">
      </div>

      <div class="col-md-3">
        <div class="input-group mb-3 mt-4">
          <input type="text" class="form-control" placeholder="Название дисциплины"
                 v-model="inputDisciplineName">
          <button class="btn btn-outline-primary" type="button" @click="searchDisciplineByName">Найти
          </button>
        </div>
      </div>
      -->

      <h4 class="text-center my-3">Список вакансий</h4>

      <!-- table start -->
<!--      <div class="table-responsive-md mt-4">-->
<!--        <table class="table table-striped table-bordered">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th scope="col">№</th>-->
<!--            <th scope="col">Код дисциплины</th>-->
<!--            <th scope="col">Название дисциплины</th>-->
<!--            <th scope="col">Кафедра</th>-->
<!--            <th scope="col">Язык обучения</th>-->
<!--            <th scope="col">Уровень обучения</th>-->
<!--            <th scope="col">Количество подавших</th>-->
<!--            <th scope="col" v-if="getUserRole == 'HRHead' || getUserRole == 'admin'">Потвердить</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody v-if="vacancy_form.educationDisciplineVacancyInfos">-->

<!--          <tr v-for="(item,index) in vacancy_form.educationDisciplineVacancyInfos.-->
<!--                        filter(educationDisciplineVacancyInfo => educationDisciplineVacancyInfo.department_status == 1)"-->
<!--              :key="index">-->
<!--            <th scope="row">{{ index + 1 }}</th>-->
<!--            <td>{{ item.discipline.code }}</td>-->
<!--            <td>{{ item.discipline.name }}</td>-->
<!--            <td>{{ item.discipline.department.name_ru }}</td>-->
<!--            <td>{{ item.discipline.language.native_name }}</td>-->
<!--            <td>{{ item.discipline.studyLevel.name }}</td>-->
<!--            <td>-->
<!--              {{ item.count }}-->
<!--              <router-link class="link-style"-->
<!--                           :to="'/vacancy-applications?discipline_vacancy_id=' + item.id">-->
<!--                Список заявок-->
<!--              </router-link>-->
<!--            </td>-->
<!--            <td v-if="getUserRole == 'HRHead' || getUserRole == 'admin'">-->
<!--              <button class="btn btn-success" type="button"-->
<!--                      @click="confirmDisciplineVacancy(item.id)"-->
<!--                      :disabled="false"-->
<!--                      :class="{ disabled: item.hr_status==1 }">-->
<!--                {{ item.hr_status == 1 ? 'Подтверждено' : 'Потвердить' }}-->
<!--              </button>-->
<!--            </td>-->

<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->

<!--      </div>-->
      <!-- table end -->

      <!-- data table start -->

      <DataTable :value="vacancy_form.applicationsVacancy" :paginator="true" :rows="15" showGridlines
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[15,30,50]"
                 filterDisplay="menu"
                 :globalFilterFields="['education_discipline_code', 'education_discipline_name']"
                 v-model:filters="filters"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск дисциплины"/>
              </span>
          </div>
        </template>

        <Column field="education_discipline_code" header="Код дисциплины"></Column>
        <Column field="education_discipline_name" header="Название дисциплины"></Column>
        <Column field="education_discipline_credit" header="Кредит"></Column>
        <Column field="department_name" header="Кафедра"></Column>
        <Column field="language" header="Язык обучения"></Column>
        <Column field="study_level_name" header="Уровень обучения"></Column>
        <Column header="Количество подавших">
          <template #body="{data}">

            <router-link class="link-style"
                         :to="'/vacancy-applications?discipline_vacancy_id=' + data.id">
              {{ data.application_count }}
            </router-link>
          </template>
        </Column>
<!--        <Column v-if="getUserRole == 'HRHead' || getUserRole == 'admin'" header="Потвердить">-->
<!--          <template #body="{data}">-->
<!--            <button class="btn btn-success" type="button"-->
<!--                    @click="confirmDisciplineVacancy(data.id)"-->
<!--                    :disabled="false"-->
<!--                    :class="{ disabled: data.hr_status==1 }">-->
<!--              {{ data.hr_status == 1 ? 'Подтверждено' : 'Потвердить' }}-->
<!--            </button>-->
<!--          </template>-->
<!--        </Column>-->




      </DataTable>


      <!-- data table end -->

    </div>
  </div>

<!--    <nav v-if="pageCount" class="d-flex justify-content-center" aria-label="Page navigation example">-->
<!--      <Paginate-->
<!--          v-model="page"-->
<!--          :page-count="pageCount"-->
<!--          :click-handler="paginateHandler"-->
<!--          :prev-text="'Предыдущий'"-->
<!--          :next-text="'Следующий'"-->
<!--          :container-class="'pagination'"-->
<!--          :page-class="'page-item'">-->
<!--      </Paginate>-->
<!--    </nav>-->

</template>

<script>

//import Paginate from '../common/Paginate.vue'
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'Vacancies',
  components: {
    //Paginate
  },

  data() {
    return {
      //page: +this.$route.query.page || 1,
      discipline_id: null,
      discipline_vacancy_id: null,
      userSyllabusCount: null,
      loading: true,
      //departmentID: 0,
      //inputDisciplineName: '',
      //sort_by_confirm:0

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'education_discipline_code': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'education_discipline_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },
    }
  },
  computed: {
    ...mapState('vacancy', ['vacancy_form', 'userSyllabusCount', 'pageCount', 'departments']),
  },

  methods: {
    ...mapActions('vacancy', ['GET_EDUCATION_DISCIPLINE_VACANCY', 'PUT_DISCIPLINE_VACANCY_HR_STATUS', 'GET_USER_SYLLABUS_COUNT', 'GET_DEPARTMENTS','GET_APPLICATIONS_VACANCY']),
    ...mapMutations('vacancy', ['SET_USER_SYLLABUS_COUNT']),

    // async paginateHandler(page) {
    //   this.page = page
    //   await this.changePage()
    // },
    // async changeFilterDepartments(e) {
    //   this.departmentID = e.target.value
    //   await this.changePage()
    // },
    // async changePage() {
    //   this.$router.push(`${this.$route.path}?page=${this.page}&department_id=${this.departmentID}`)
    //   this.loading = true
    //   await this.GET_EDUCATION_DISCIPLINE_VACANCY({
    //     page: this.page,
    //     department_id: this.departmentID,
    //     count: true,
    //     sort_by_confirm:this.sort_by_confirm
    //   })
    //   this.loading = false
    // },
    // async searchDisciplineByName() {
    //   console.log(this.inputDisciplineName)
    //   this.loading = true
    //   await this.GET_EDUCATION_DISCIPLINE_VACANCY({
    //     page: this.page,
    //     department_id: this.departmentID,
    //     count: true,
    //     discipline_name: this.inputDisciplineName,
    //     sort_by_confirm:this.sort_by_confirm
    //   })
    //   this.loading = false
    // },
    async confirmDisciplineVacancy(id) {
      await this.PUT_DISCIPLINE_VACANCY_HR_STATUS(id)
      await this.GET_APPLICATIONS_VACANCY();
      // await this.GET_EDUCATION_DISCIPLINE_VACANCY({
      //   page: this.page,
      //   department_id: this.departmentID,
      //   count: true,
      //   sort_by_confirm:this.sort_by_confirm
      // })
    },
    // async getUserCount(id) {
    //   let count = await this.GET_USER_SYLLABUS_COUNT(id)
    //   console.log(count, "USER SYLLABUS COUNT")
    //   return count
    // }
  },
  async mounted() {
    await this.GET_DEPARTMENTS()
    await this.GET_APPLICATIONS_VACANCY()
    this.loading = false





    // let department_id = 0
    // if (this.getUserRole == 'departmentHead') {
    //   department_id = getCookie('DEPARTMENT_ID')
    //   this.departmentID = department_id
    //
    // } else if (this.departmentID) {
    //   department_id = this.departmentID
    // }
    // await this.GET_EDUCATION_DISCIPLINE_VACANCY({page: this.page, department_id, count: true,
    //   sort_by_confirm:this.sort_by_confirm});
    // this.loading = false;
    // console.log(this.pageCount, 'vacancies pageCount')
  },
  //  watch:{
  //    async sort_by_confirm(before,current){
  //     await this.GET_EDUCATION_DISCIPLINE_VACANCY({
  //       page: this.page,
  //       department_id: this.departmentID,
  //       count: true,
  //       sort_by_confirm:this.sort_by_confirm
  //     })
  //   }
  // }
}
</script>
<style scoped>
.link-style {
  text-decoration: none;
}
</style>